.card {
  --bs-card-cap-bg: hsla(0,0%,100%,.5);
}

:root {
  --bc-body-bg-transparency: 0.5;
  --bc-glass-blur: 50px;
}


.header {
  .logo {
    max-height: 4.5rem;
  }
}

.login-or {

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 15%;
    height: 1px;
    background: var(--bs-highlight-color);
  }

  &:before {
    left: 30%;
  }

  &:after {
    right: 30%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.copy-txt {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
}

.fullpage-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .6);
  z-index: 99;

  .spinner-border {
    top: calc(50vh - 50px);
    left: calc(50vw - 50px);
    position: fixed;
  }
}
.h-1 {
  height: 1rem;
}
.h-1-5 {
  height: 1.5rem;
}
.h-2-2 {
  height: 2.2rem;
}
.maxH-5 {
  max-height: 5rem;
}
.h-5 {
  height: 5rem;
}
.maxH-vh100 {
  max-height: 100vh;
}
.h-7 {
  height: 7rem;
}
.h-8-5 {
  height: 8.5rem;
}
.h-15 {
  height: 15rem;
}

.lh-1-5 {
  line-height: 1.5rem;
}

.card-head {
  padding: 1rem 1rem 0 1rem;

}

.card-head,
.card-footer {
  .icon {
    height: 1.5rem;
    margin-left: 0.5rem;
    //cursor: pointer;

    &.logo {
      margin: 0;
      //cursor: auto;
    }
  }

}

.card-footer {
  .icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

.blc_image_sm {
	height: 3.1rem;
	width: 3.1rem; 
}

.blc_image_md {
	height: 5.6rem;
	width: 5.6rem; 
}

.blc_image_lg {
	//height: 6.8rem;
	width: 6.8rem; 
}

.my-apps {
  .bc-link-block {
    border-radius: 7px;
    text-align: center;
    min-height: 4.5rem;
    width: 4.5rem;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    padding: 0.2rem;

    &.disabled {
      opacity: 0.5;
      position: none;
    }

    &.one {
      background: var(--bs-primary);
    }

    &.two {
      background: var(--bs-secondary);
    }

    &.three {
      background: var(--bs-orange);
    }

    &.four {
      background: var(--bs-danger);
    }

    &.five {
      background: var(--bs-gray-dark);
    }

    &.six {
      background: var(--bs-gray);
    }

    &.seven {
      background: var(--bs-gray-900);
    }

  }
  .bc-link-block-sm {
    border-radius: 7px;
    text-align: center;
    //min-height: 4.5rem;
    width: 4.5rem;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    padding: 0.1rem;
  }
}
.bg-orange {
  background-color: var(--bs-orange);
}
.bg-teal {
  background-color: var(--bs-teal);
}
.bg-cyan {
  background-color: var(--bs-cyan);
}
.bg-gray {
  background-color: var(--bs-gray);
}
.bg-gray-500 {
  background-color: var(--bs-gray-500);
}
.bg-purple {
  background-color: var(--bs-purple);
}
.bg-indigo {
  background-color: var(--bs-indigo);
}
.bg-pink {
  background-color: var(--bs-pink);
}
.bg-green {
  background-color: var(--bs-green);
}
.bg-gray-dark {
  background-color: var(--bs-gray-dark);
}
.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
}
.bg-danger-bg-subtle{
  background-color: var(--bs-danger-bg-subtle);
}
.bg-warning-bg-subtle{
  background-color: var(--bs-warning-bg-subtle);
}

.bl_border-orange {
  border: 1px solid var(--bs-orange) !important;
}

.prompt-card {
  height: auto;
  position: relative;

  .prompt-card-image-container img {
    width: 100%;
    height: auto;
  }

  &:hover .prompt-card-image-actions {
    display: block;
  }
  .prompt-card-image-actions {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.2rem;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    display: none;
    z-index: 1;
    text-align: center;

    img {
      height: 1rem;
      margin: 0.1rem 0.2rem;
      // cursor: pointer;
    }
  }

  .overlay-band {
    position: absolute;
    top: 73%;
    left: 5%;
    padding: 0.2rem; 
    height: 1.5rem;
    width: 90%;
    background-color: white; 
    opacity: 0.9;
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    //justify-content: center;
    //vertical-align: middle;
    // line-height: 1rem;
    border-radius: 5px;
    color: var(--bs-dark);
  }
  .overlay-band span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
}

.card-header,
.card-footer {
  padding: var(--bs-card-cap-padding-y) calc(var(--bs-card-cap-padding-x) - 1.5rem)
}

.alert-container {
  position: fixed;
  top: 10%;
  width: 50%;
  left: 25%;
}

.disabled-item {
  pointer-events: none;
  opacity: .5;
}

.h-35vh {
  height: 35vh;
}
.h-41vh {
  height: 41vh;
}
.h-140vh {
  height: 140vh;
}
.bc-text-start-tooltip .tooltip-inner {
  text-align: left !important;
}
.bc-text-limit-3-tooltip .tooltip-inner {
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  line-clamp: 3; /* Limits to 3 lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.scrollbar::-webkit-scrollbar {
  width: 0.3rem;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 3rem;
  background-color: rgba(172, 172, 172, .2);
}

.scrollbar::-webkit-scrollbar-thumb {
  // border-radius: 3rem;
  // border: 0.3rem solid rgba(172, 172, 172, .5);
  // border-left: 0;
  // border-right: 0;
  background-color: rgba(172, 172, 172, .8);

}

input.small-placeholder::placeholder {
  font-size: 1rem;
  text-align: left;
}

// editor Stylings
.rsw-editor {
  border: 1px solid #00000032;

  button[title="Bold"],
  button[title="Italic"],
  button[title="Underline"],
  button[title="Strike through"],
  button[title="Link"],
  button[title="Clear formatting"],
  button[title="HTML mode"],
  select[title="Styles"],
  .rsw-separator {
    display: none;
  }

  .rsw-toolbar {
    background: #8080801d;
  }

  button.rsw-btn {
    font-size: 1rem;
    padding: 0.25rem 0.7rem;
    background: #fff;
    margin: 3px;
    border-radius: 6px;
    width: auto;
    height: auto;
    border: 1px solid #ddd;
    color: #707070;
  }
}

// input type number - Hide arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.public-url {
  display: inline-block;
  width: 8.8rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid var(--bs-secondary);
  color: var(--bs-secondary);
  margin-right: 0.5rem;
}


.photoCanvas {
  height: 2.4rem;
  width: 2.4rem;
}

.blcModalContentS {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: auto;
  max-height: 11rem;
}

.scrollbarSlim1, .scrollbarSlim2 {
  overflow-y: scroll !important;
}

.fileHeight {
  line-height: 50px;
}

.drop-zone {
  transition: background-color 0.3s ease;
}

.drop-zone.dragging {
  background-color: #e1e1e1;
}
// Table of sticky header and sticky column
.table-sticky-container {
  overflow: auto;
  max-height: 100%;

  thead {
      th {
          position: sticky;
          top: 0;
          background: white;
          z-index: 1;
          vertical-align: top;

          &.sticky-col.sticky-row {
              z-index: 3;
          }
      }
  }

  .sticky-col {
      position: sticky;
      left: 0;
      background: white;
      z-index: 2;
  }
}

.ribbon-container {
  position: relative;
  overflow: hidden;

  .ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 60px;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -25px;
      left: -35px;  
      width: 150%;
      height: 8%;
      transform: rotate(-45deg); 
    }

    &-primary::before {
      background-color: var(--bs-primary);
    }

    &-grey::before {
      background-color: var(--bs-gray);
    }

    &-orange::before {
      background-color: var(--bs-orange);
      left: -25px;
      height: 8%;
      top: -25px;
      width: 150%;
      transform: rotate(-45deg);  
    }
  }
}
//Classes for columns
.bc-column-3 {
  @extend .col-xl-3;
  @extend .col-lg-3;
  @extend .col-md-12;
  @extend .col-sm-12;
  @extend .mb-3;
}
.bc-column-6 {
  @extend .col-xl-6;
  @extend .col-lg-6;
  @extend .col-md-12;
  @extend .col-sm-12;
  @extend .mb-3;
}
.bc-column-9 {
  @extend .col-xl-9;
  @extend .col-lg-9;
  @extend .col-md-12;
  @extend .col-sm-12;
  @extend .mb-3;
}
.bc-column-12 {
  @extend .col-xl-12;
  @extend .col-lg-12;
  @extend .col-md-12;
  @extend .col-sm-12;
  @extend .mb-3;
}

// .bc-disable-drag {
//   cursor: not-allowed; 
// }

.bc-disable-drag:hover {
  opacity: 0.5; 
}


.bc-blinking-text {
  animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.bc-horizontal-scroll::-webkit-scrollbar {
  height: 0.3rem;
}

.bc-horizontal-scroll::-webkit-scrollbar-track {
  border-radius: 3rem;
  background-color: rgba(172, 172, 172, .2);
}

.bc-horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(172, 172, 172, .8);
}

.bc-highlighted-text {
  background-color: var(--bs-primary);
  color: white;
  padding: 2px 4px;
  border-radius: 3px;
}

.bc-sticky-bgColor {
  background-color: #eee;
}

.screenshot-card {
  overflow: hidden; /* Prevent excessive stretching */
  background: linear-gradient(135deg, #e0f7fa, #80deea); /* Subtle gradient */
  border-radius: 1rem; /* Rounded corners */
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2); /* Elevated card look */
  padding: 1.5rem; /* Spacious padding */
  //color: #333; /* Text color for good contrast */
  width: 37.5rem; /* Fixed width (equivalent to 600px) */
  max-width: 90%; /* Ensure it fits smaller screens */
  margin: auto; /* Center alignment */
}
